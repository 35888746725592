<template>
  <el-main>
    <page-title />
    <div class="partition-area">
      <ics-search-inner :search-form="searchForm" more-btn="高级搜索" :fold-search.sync="showFoldSearch" @submit-search="search" @clear-search="clearSearch">
        <template>
          <el-col :span="6">
            <el-form-item label="白名单编号">
              <el-input v-model="searchForm.blackCode" placeholder="请输入要搜索的白名单编号" />
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item label="融资企业名称">
              <el-input v-model="searchForm.customerName" placeholder="请输入要搜索的融资企业名称" />
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item label="统一社会信用代码">
              <el-input v-model="searchForm.customerSocialNo" placeholder="请输入要搜索的统一社会信用代码" />
            </el-form-item>
          </el-col>
          <el-col />
          <el-col v-show="showFoldSearch" :span="6">
            <el-form-item label="创建人">
              <el-input v-model="searchForm.createdBy" placeholder="请输入要搜索的创建人" />
            </el-form-item>
          </el-col>
          <el-col v-show="showFoldSearch" :span="12">
            <el-form-item label="创建时间">
              <ics-search-date :date.sync="createdArr" />
            </el-form-item>
          </el-col>
        </template>
      </ics-search-inner>
    </div>
    <div class="partition-table">
      <ics-table-inner v-loading="loading.list" :column-option="columnOption" :table-data="tableList.list" :page-num="tableList.pageNum" :page-size="tableList.pageSize" :pages="tableList.pages" :hide-export-btn="true" @changePageNum="changePageNum">
        <template slot="btn-inner">
          <el-button type="primary" @click="enter">
            导入白名单
          </el-button>
        </template>
        <template slot="table-columns-before">
          <el-table-column fixed="left" label="序号" type="index" :index="indexMethod" />
        </template>
        <template slot="table-columns-after">
          <el-table-column fixed="right" label="操作" width="100">
            <template v-slot="scope">
              <ics-auth-manage code="白名单管理">
                <el-link v-if="[1, 2].includes(scope.row.state) && scope.row.relation === 2" type="primary" style="margin-left: 10px;" class="text-btn" @click="dialogAudit(scope.row.id)">
                  审批
                </el-link>
              </ics-auth-manage>
              <el-link v-if="scope.row.state === 6 && scope.row.relation === 2" type="danger" class="text-btn" @click="deleteWhite(scope.row.id)">
                删除
              </el-link>
            </template>
          </el-table-column>
        </template>
      </ics-table-inner>
    </div>
    <ics-uploadi-inner :upload-dialog="dialog.enter" @onSuccessFile="onSuccessFile" />
    <ics-audit-inner :loading-submit="loadingSubmit" :dialog="dialog.audit" @submit="submitAudit" />
  </el-main>
</template>

<script>
import { basePageListMixin } from '@/assets/js/mixins'
import utils from '@/assets/js/utils'
import IcsUploadiInner from './components/upload-inner'
import IcsAuthManage from '@/components/auth-manage'
import IcsAuditInner from '@/components/audit-inner'
export default {
  components: { IcsUploadiInner, IcsAuthManage, IcsAuditInner },
  mixins: [basePageListMixin],
  data () {
    return {
      showFoldSearch: false,
      baseSearchForm: {
        blackCode: '',
        customerName: '',
        customerSocialNo: '',
        createdBy: '',
        createdBeginAt: '',
        createdEndAt: ''
      },
      loadingSubmit: {
        submit: false
      },
      dialog: {
        enter: {
          visible: false,
          title: '导入白名单'
        },
        move: {
          visible: false,
          title: '移入白名单'
        },
        audit: {
          visible: false,
          title: '审批白名单'
        }
      },
      operationType: '',
      businessId: '',
      appForm: {
        id: '',
        customerName: '',
        customerSocialNo: '',
        reason: ''
      },
      customerList: [],
      rules: {
        id: [
          { required: true, message: '请选择移入白名单的企业', trigger: 'change' }
        ]
      },
      columnOption: [
        { label: '白名单编号', prop: 'blackCode', formatter: this.utils.isEffective, minWidth: 140 },
        { label: '所属核心企业', prop: 'coreName', formatter: this.utils.isEffective, minWidth: 140 },
        { label: '融资企业名称', prop: 'customerName', formatter: this.utils.isEffective, minWidth: 140 },
        { label: '统一社会信用代码', prop: 'customerSocialNo', formatter: this.utils.isEffective, minWidth: 140 },
        { label: '创建时间', prop: 'createdAt', formatter: this.utils.isEffective, minWidth: 140 },
        { label: '创建人', prop: 'createdBy', formatter: this.utils.isEffective, minWidth: 140 },
        { label: '导入人', prop: 'relation', formatter: (row, col, cell) => this.utils.statusFormat(cell, 'relationType'), minWidth: 140 },
        { label: '业务状态', prop: 'state', formatter: (row, col, cell) => this.utils.statusFormat(cell, 'whiteBusinessState'), minWidth: 140 },
      ]
    }
  },
  computed: {
    createdArr: utils.computedDate('createdBeginAt', 'createdEndAt')
  },
  methods: {
    getList () {
      this.loading.list = true
      this.api.risk.white.pageList(this.utils.filterTrimValue(this.searchForm)).then(result => {
        this.tableList = result.data.data
      }).finally(() => {
        this.loading.list = false
      })
    },
    move () {
      this.dialog.move.visible = true
      this.getCustomerList()
    },
    enter () {
      this.dialog.enter.visible = true
    },
    dialogAudit (val) {
      this.businessId = val
      this.dialog.audit.visible = true
    },
    getCustomerList () {
      this.api.risk.black.getBlackList().then(result => {
        this.customerList = result.data.data
      }).catch(() => {})
    },
    companyChange (val) {
      const data = this._.find(this.customerList, { id: val }) || {}
      const { customerName, customerSocialNo } = data
      this.appForm = this._.assign(this.appForm, { customerName, customerSocialNo })
    },
    onSuccessFile (response) {
      const data = response.data
      const massageList = []
      if (JSON.stringify(data.failList) !== '{}') {
        Object.keys(data.failList).forEach(key=>{
          massageList.push(`${key}: ${data.failList[key]}`)
        })
        this.$alert(`<div style="width: 390px; height: 300px; overflow:scroll; overflow-x:hidden;"><p>${ massageList.join('<br>') }</p>`, '导入错误', {
          dangerouslyUseHTMLString: true,
          customClass: 'message_box_alert',
          cancelButtonText: '关闭'
        })
      }
      this.getList()
    },
    submitAudit (formData) {
      return new Promise((resolve, reject) => {
        this.loadingSubmit.submit = true
        let data = formData || {}
        console.log(formData)
        this.api.risk.white.auditWhite(this.businessId, data.state, data.reason).then(result => {
          if (result.data.success === true) {
            this.loadingSubmit.submit = false
            this.dialog.audit.visible = false
            this.$message.success('操作成功')
            this.getList()
          } else {
            this.$message.error(result.data.message)
            this.loadingSubmit.submit = false
          }
          resolve(result.data.data)
        }).catch(e => {
          reject(e)
        }).finally(() => {
          this.loadingSubmit.submit = false
        })
      })
    },
    submitForms () {
      this.$refs.appForm.validate().then(() => {
        this.loading.submit = true
        this.api.risk.white.moveInWhite(this.appForm.id, this.appForm.reason).then(result => {
          if (result.data.success === true) {
            this.loading.submit = false
            this.dialog.move.visible = false
            this.$message.success('操作成功')
            this.getList()
          } else {
            this.$message.error(result.data.message)
          }
        }).catch(e => {
          this.loading.submit = false
        })
      })
    },
    deleteWhite (val) {
      this.api.risk.white.deleteWhite(val).then(result => {
        if (result.data.success === true) {
          this.loading.submit = false
          this.$message.success('操作成功')
          this.getList()
        } else {
          this.$message.error(result.data.message)
        }
      }).catch(e => {
        this.loading.submit = false
      })
    }
  }
}
</script>

<style>

</style>
