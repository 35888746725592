var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.show
    ? _c(
        "span",
        {
          staticClass: "authority-management",
          staticStyle: { "margin-left": "10px", "margin-right": "10px" }
        },
        [_vm._t("default")],
        2
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }