<template>
  <el-main>
    <ics-dialog-inner :loading="loadingSubmit.submit" :visible.sync="dialog.visible" :title="dialog.title" submit-title="提交" cancel-title="取消" class="form-dialog" width="50%" @submit="submitForms" @close="close">
      <el-form ref="appForm" :model="appForm" :rules="rules" label-width="180px" :label-suffix="constants.labelSuffix">
        <ics-page-inner title="" :show-header="true">
          <el-col :span="24">
            <el-form-item label="审批结果" prop="state" :rules="{ required: true, message: '请选择审批结果', trigger: 'change' }">
              <el-radio-group v-model="appForm.state">
                <el-radio label="1">
                  通过
                </el-radio>
                <el-radio label="0">
                  拒绝
                </el-radio>
              </el-radio-group>
            </el-form-item>
          </el-col>
          <el-col :span="24">
            <el-form-item prop="reason" label="审批意见" :rules="{ required: true, message: '请输入审批意见', trigger: 'blur' }">
              <el-input v-model="appForm.reason" placeholder="请输入审批意见(限300字)" :maxlength="300" type="textarea" />
            </el-form-item>
          </el-col>
        </ics-page-inner>
      </el-form>
    </ics-dialog-inner>
  </el-main>
</template>

<script>
import IcsDialogInner from '@/components/dialog-inner'
import { routeEnterMixin } from '@/assets/js/mixins'
export default {
  name: 'IcsAuditInner',
  components: { IcsDialogInner },
  mixins: [routeEnterMixin],
  props: {
    dialog: {
      type: Object,
      default() {
        return {}
      }
    },
    loadingSubmit: {
      type: Object,
      default() {
        return {}
      }
    },
  },
  data () {
    return {
      appForm: {
        state: '',
        reason: ''
      },
      rules: {}
    }
  },
  created () {},
  methods: {
    submitForms () {
      this.$refs.appForm.validate().then(() => {
        this.$emit('submit', this.appForm)
      })
    },
    close () {
      this.appForm.state = ''
      this.appForm.reason = ''
      this.appForm.clearValidate()
    }
  }
}
</script>

<style scoped>

</style>
