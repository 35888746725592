import { render, staticRenderFns } from "./upload-inner.vue?vue&type=template&id=2dc8763a&scoped=true&"
import script from "./upload-inner.vue?vue&type=script&lang=js&"
export * from "./upload-inner.vue?vue&type=script&lang=js&"
import style0 from "./upload-inner.vue?vue&type=style&index=0&id=2dc8763a&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2dc8763a",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/mfs/build/project-ics/dev/ics-channel-vue/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('2dc8763a')) {
      api.createRecord('2dc8763a', component.options)
    } else {
      api.reload('2dc8763a', component.options)
    }
    module.hot.accept("./upload-inner.vue?vue&type=template&id=2dc8763a&scoped=true&", function () {
      api.rerender('2dc8763a', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/views/riskManagement/white/components/upload-inner.vue"
export default component.exports