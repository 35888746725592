<template>
  <span v-if="show" class="authority-management" style="margin-left: 10px;margin-right: 10px;">
    <slot />
  </span>
</template>

<script>
export default {
  name: 'IcsAuthManage',
  props: ['componentCode', 'code', 'powerFeatureList'],
  data () {
    return {
      show: false,
      authCode: this.code || this.componentCode,
      features: this.powerFeatureList || this.$store.state.powerFeatureList
    }
  },
  created () {
    this.getFeatureList()
  },
  methods: {
    // 获取设置的按钮列表
    getFeatureList () {
      const featureList = this.features
      if (typeof this.authCode === 'object') {
        if (this.authCode.condition === 'or') {
          featureList.forEach((item) => {
            item.forEach(val => {
              if (this.authCode.code.indexOf(val.menu.menuName) !== -1 && val.menu.assignMenuFeature === 1) {
                this.show = true
                return false
              }
            })
          })
        } else {
          let show = true
          this.authCode.code.forEach(value => {
            featureList.forEach((item) => {
              if (!this._.find(item.menu, {menuName: value})) {
                show = false
              }
            })

          })
          this.show = show
        }
      } else {
        featureList.forEach((item) => {
          item.forEach(val => {
            if (val.menu.menuName === this.authCode && val.menu.assignMenuFeature === 1) {
              this.show = true
              return false
            }
          })
        })
      }
    }
  }
}
</script>

<style scoped>

</style>
